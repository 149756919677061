<template>
  <div class="header--categories">
    <div class="container d-flex align-center">
      <div class="all--categories">
        <div
          v-for="(item, index) in subheaders"
          :key="`main-cat-header-${index}`"
          class="single--category"
          :class="{ selected: $route.path === item.url }"
        >
          <router-link :to="item.url">
            <div style="position: relative">
              {{ $t(item.name) }}
              <div v-if="item.unread" class="unread--counter">{{ item.unread }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  name: 'header-categories',
  mixins: [HelperMixin],
  data: () => ({}),
  computed: {
    ...mapState({
      enquiriesUnreadCount: (state) => state.global.enquiriesUnreadCount,
    }),
    subheaders() {
      return [
        {
          name: 'home.sidebarMenu.product',
          url: '/product',
        },
        {
          name: 'home.sidebarMenu.myListing',
          url: '/',
        },
        {
          name: 'home.sidebarMenu.featuredAgent',
          url: '/featured-agent',
        },
        {
          name: 'home.sidebarMenu.myOrder',
          url: '/order',
        },
        {
          name: 'home.sidebarMenu.coinUsage',
          url: '/coin-usage',
        },
        {
          name: 'home.sidebarMenu.myLeads',
          url: '/leads',
          unread: this.enquiriesUnreadCount,
        },
      ];
    },
  },
};
</script>

<style scoped></style>
